import { FC, useRef } from "react";
import {
  Button,
  Input,
  Form,
  notification,
  Modal,
  Space,
  Divider,
  Radio,
  DatePicker,
  Checkbox,
  InputRef,
  Select,
} from "antd";
import { observer, useLocalObservable } from "mobx-react";
import dayjs from "dayjs";
import { toJS } from "mobx";
import advancedFormat from "dayjs/plugin/advancedFormat";
import "../Admin.css";

dayjs.extend(advancedFormat);

interface Props {
  store: any;
  adminController: any;
}

export const RegistrationModal: FC<Props> = observer((props) => {
  const error = useLocalObservable(() => ({ message: "" }));
  const [api, contextHolder] = notification.useNotification();
  const bibNoInput = useRef<InputRef>(null);
  const formRef = useRef(null);

  console.log("RENDER REG MODAL");

  let selectedRegistration = toJS(props.adminController.selectedRegistration);

  selectedRegistration.dob = selectedRegistration.dob ? dayjs(selectedRegistration.dob) : null;

  const subEvents = props.adminController.selectedEvent.subEvents;

  console.log("selectedRegistration", selectedRegistration);

  bibNoInput.current?.focus();

  const handleKeyUp = (event) => {
    // Enter
    if (event.keyCode === 13) {
      formRef.current.submit();
    }
  };

  const handleBibInput = (evt) => {
    let key;

    // Handle paste
    if (evt.type === "paste") {
      key = evt.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      key = evt.keyCode || evt.which;
      key = String.fromCharCode(key);
    }

    if (evt.keyCode === 8) {
      return;
    }

    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      evt.returnValue = false;
      if (evt.preventDefault) evt.preventDefault();
    }
  };

  const handleDeleteRegistration = async () => {
    await props.adminController.deleteRegistration();
    //if (result) {
    api.success({
      message: "Registration Deleted Successfully!",
      description: "",
    });
    //}
  };

  let enrolledTextClass = "text-gray-500 mt-4 mb-2";
  if (selectedRegistration.lateEntry) {
    enrolledTextClass += " bg-red-500 rounded-md p-2 text-white";
  }

  let dateClass = "";
  console.log(dayjs(selectedRegistration.dob).diff(dayjs(), "year"));

  if (Math.abs(dayjs().diff(dayjs(selectedRegistration.dob), "years")) < 5) {
    dateClass = "bg-red-300";
  }

  const title =
    props.adminController.registrationType === "edit"
      ? selectedRegistration?.firstName + " " + selectedRegistration?.lastName
      : "Register New Entry";

  return (
    <Modal
      title={title}
      open={true}
      footer={null}
      onCancel={() => (props.adminController.isRegModalOpen = false)}
      width={"600px"}
      // style={!selectedRegistration.paid && { backgroundColor: "#ffcccc" }}
    >
      {contextHolder}
      <Divider style={{ marginTop: "15px" }} />
      <Form
        onFinish={
          props.adminController.registrationType === "edit"
            ? props.adminController.updateRegistration
            : props.adminController.createRegistration
        }
        initialValues={selectedRegistration}
        className="registrationForm"
        ref={formRef}
        onKeyUp={handleKeyUp}
      >
        <Form.Item name="_id" className="hidden"></Form.Item>
        <Form.Item name="event" className="hidden"></Form.Item>
        <div className="flex">
          <div className="flex-1 flex space-x-2 mb-2">
            <Form.Item name="bib" className="w-20 font-bold">
              <Input
                name="bib"
                placeholder="Bib"
                className="text-lg text-center"
                maxLength={4}
                onKeyDown={handleBibInput}
                ref={bibNoInput}
              />
            </Form.Item>
            <Form.Item name="subEvent" className="flex-1 text-center text-lg">
              <Select className="text-lg">
                {subEvents.map((subEvent) => (
                  <Select.Option key={subEvent.ref} value={subEvent.ref}>
                    {subEvent.ref}
                  </Select.Option>
                ))}
              </Select>
              {/* <Input name="subEvent" className="text-lg" /> */}
            </Form.Item>
          </div>
          <div className={"flex flex-1 checkbox-lg " + (!selectedRegistration.paid && "back-red")}>
            {selectedRegistration.lateEntry && (
              <div
                className="hidden md:block ml-2 px-4 bg-red-500 h-fit w-28 rounded-md font-bold text-white"
                style={{ lineHeight: "34px" }}
              >
                LATE ENTRY
              </div>
            )}
            <span className="flex-1 text-lg text-right pr-2" style={{ lineHeight: "34px" }}>
              Paid:
            </span>
            <Form.Item name="paid" className="text-right" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </div>
        </div>
        <div className="flex space-x-2">
          <Form.Item
            name="firstName"
            className="flex-1 mb-4"
            rules={[
              {
                required: true,
                message: "Please input First Name!",
              },
            ]}
          >
            <Input name="firstName" placeholder="First Name" />
          </Form.Item>
          <Form.Item
            name="lastName"
            className="flex-1 mb-4"
            rules={[
              {
                required: true,
                message: "Please input Last Name!",
              },
            ]}
          >
            <Input name="lastName" placeholder="Last Name" />
          </Form.Item>
        </div>
        <div className="md:flex md:space-x-2">
          <Form.Item name="email" className="flex-1 mb-3">
            <Input name="email" placeholder="Email" />
          </Form.Item>
          <Form.Item name="contactNo" className="flex-1 mb-3">
            <Input name="contactNo" placeholder="Contact Number" />
          </Form.Item>
        </div>
        <div className="flex space-x-2">
          <Form.Item
            name="gender"
            className="flex-1 mb-3"
            rules={[
              {
                required: true,
                message: "Please input Gender!",
              },
            ]}
          >
            <Radio.Group style={{ marginLeft: "5px" }}>
              <Radio value="M">Male</Radio>
              <Radio value="F">Female</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="dob"
            className="mb-3 flex-1"
            rules={[
              {
                type: "object" as const,
                required: true,
                message: "Please input your Date of Birth!",
              },
            ]}
          >
            <DatePicker className={dateClass} format={"DD/MM/YYYY"} showToday={false} placeholder={"DD/MM/YYYY"} />
          </Form.Item>
        </div>
        <div className="flex space-x-2">
          <Form.Item name="resident" className="flex-1 mb-3" valuePropName="checked">
            <Checkbox>Resident</Checkbox>
          </Form.Item>
          <Form.Item name="isBundle" className="flex-1 mb-3 text-left" valuePropName="checked">
            <Checkbox disabled>Bundle</Checkbox>
          </Form.Item>
        </div>
        <div className={enrolledTextClass}>
          Registration Date: {dayjs(selectedRegistration.createdAt).format("Do MMMM YYYY - HH:mm:ss")}
        </div>
        <hr className="mb-4" />
        <div className="flex">
          <div className="flex-1">
            <Button className="bg-red-500" type="primary" onClick={handleDeleteRegistration}>
              Delete
            </Button>
          </div>
          <div className="flex-1 text-right">
            <Button className="flex-1 bg-blue-500" type="primary" htmlType="submit">
              {props.adminController.registrationType === "edit" ? "Update" : "Add"}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
});
