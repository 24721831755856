import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Button } from "antd";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { inject, observer } from "mobx-react";
dayjs.extend(advancedFormat);

interface Props {
  events: any[];
  controller?: any;
}

// const CommentsModal: FC<{ dashboardController?: any }> = inject('dashboardController')(
// 	observer(({ dashboardController }) => {

const Events: FC<Props> = inject("controller")(
  observer((props) => {
    const navigate = useNavigate();

    console.log("RENDER EVENTS");

    const handleRegister = (event: any) => {
      props.controller.selectedEvent = event;
      navigate(`/register/${event._id}`);
    };

    const handleLateEntryClick = (event: any) => {
      props.controller.selectedEvent = event;
      props.controller.showLateEntryModal = true;
    };

    const handleApplicationRedirect = (url: string) => {
      window.open(url, "_blank");
    };

    const renderLateEnries = (event) => {
      const entriesLeft = event.entriesLeft;

      if (!event.active || !event.lateEntries) {
        return null;
      }

      return (
        <span>
          {entriesLeft <= 0 && (
            <Button className="bg-gray-500 ml-2" type="primary" onClick={() => handleLateEntryClick(event)}>
              Late Entry
            </Button>
          )}
        </span>
      );
    };

    const renderRegisterButton = (event) => {
      const entriesLeft = event.entriesLeft;

      let entriesActive = event.active;
      let buttonText = entriesActive ? "Apply" : "Applications Out Soon";

      //check if event date expired
      if (dayjs(event.eventDate).isBefore(dayjs())) {
        entriesActive = false;
        buttonText = "Applications Closed";
      }

      //event not hosted on application
      if (event.applicationUrl) {
        return (
          <Button
            className="bg-gray-500"
            type="primary"
            onClick={() => handleApplicationRedirect(event.applicationUrl)}
            disabled={!entriesActive}
          >
            {buttonText}
          </Button>
        );
      }

      //check if limit has been reached
      if (entriesLeft !== null && entriesLeft <= 0) {
        entriesActive = false;
        buttonText = "Sold Out";
      }

      return (
        <Button className="bg-gray-500" type="primary" onClick={() => handleRegister(event)} disabled={!entriesActive}>
          {buttonText}
        </Button>
      );
    };

    const renderRemainingEntries = (event) => {
      const entriesLeft = event.entriesLeft;

      if (!event.active || !entriesLeft || entriesLeft > 50 || entriesLeft < 0) {
        return null;
      }

      return <span className="ml-3 text-sm text-red-600 font-semibold">{entriesLeft + " Entries Left"}</span>;
    };

    const renderEvents = () => {
      return props.events.map((event) => {
        return (
          <div key={event._id}>
            <div key={event._id} className="mb-8">
              <img src={event.image} alt={event._id} style={{ width: "360px" }} />
              <div className="mt-2 font-bold">{event.name}</div>
              <p className="mt-2 mb-4">
                {event.isBundle ? "Discounted Price" : dayjs(event.eventDate).format("Do MMMM YYYY - HH:mm")}
              </p>
              <div className="inline">
                {renderRegisterButton(event)}
                {renderLateEnries(event)}
                {renderRemainingEntries(event)}
              </div>
            </div>
          </div>
        );
      });
    };

    return <div className="inline-flex flex-wrap justify-center md:gap-x-6">{renderEvents()}</div>;
  })
);

export default Events;
