import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Modal } from "antd";
import { inject, observer } from "mobx-react";

interface Props {
  controller?: any;
}

const LateEntryModal: FC<Props> = inject("controller")(
  observer((props) => {
    const navigate = useNavigate();

    const handleRegister = (event: any) => {
      navigate(`/register/${event._id}`);
    };

    return (
      <Modal
        title="Late Entry - Disclaimer"
        open={props.controller.showLateEntryModal}
        okText="Agree"
        onOk={() => handleRegister(props.controller.selectedEvent)}
        onCancel={() => (props.controller.showLateEntryModal = false)}
      >
        <p className="text-justify">
          Please note that late entries will <b>NOT</b> be eligible for a <b>medal or a goody bag</b> due to limited
          availibility. However, late entrants can still participate, enjoy and compete for race trophies which will be
          elgible to <b>ALL</b>.
        </p>
        <p className="mb-5">
          If you are still interested please agree to these terms and proceed with the registration.
        </p>
      </Modal>
    );
  })
);

export default LateEntryModal;
