import React, { FC, useEffect } from "react";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react";
import { Button, DatePicker, Form, Input, Radio, Select, notification } from "antd";
import { makeObservable, observable } from "mobx";

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault("Europe/Rome");

interface Props {
  store: any;
}
const Registration: FC<Props> = observer((props) => {
  console.log("RENDER REGISTRATION");

  const [api, contextHolder] = notification.useNotification();

  const { eventId } = useParams();

  const state = useLocalObservable(() => ({
    event: null,
  }));

  useEffect(() => {
    // Anything in here is fired on component mount.
    state.event = props.store.events.find((event: any) => event._id === eventId);

    const getEvent = async () => {
      state.event = await props.store.getData(`events/${eventId}`);
    };

    if (!state.event) {
      getEvent();
    }
  }, [props.store, eventId, state]);

  let event = state.event;

  if (event) {
    event.notifyLateEntries = event.lateEntries && event.entriesLeft !== null && event.entriesLeft <= 0;
  }

  const handleRegisterEvent = async (form) => {
    let result;

    form.dob = dayjs(form.dob).format("YYYY-MM-DD");

    result = await props.store.postData(`events/${event._id}/register`, form);

    //redirect to stripe only if registration was successful
    if (result.data?.registrationIds && result.data.registrationIds.length > 0) {
      const priceCode = result.data.priceCode; //event.subEvents.find((subEvent: any) => subEvent.ref === form.subEvent).priceCode;
      const checkoutSession = await props.store.postData("payments/create", {
        registrationIds: result.data.registrationIds,
        eventId: event._id,
        email: form.email,
        priceCode: priceCode,
      });

      console.log("checkoutSessionUrl", checkoutSession);

      if (checkoutSession.status === 500) {
        console.error(checkoutSession.data?.message);
      } else {
        window.location.href = checkoutSession.data;
      }
    } else {
      api.error({
        message: "Registration Failed",
        description: "Please try again and if problem persists contact us on our FB Events Page.",
      });

      console.error("Could not create registration");
    }
  };

  const renderHeader = () => {
    let headerText = event.name;

    //check if limit has been reached
    if (event.notifyLateEntries) {
      headerText += " - Late Entry";
    }

    return <div className="p-4 flex items-center justify-center text-lg font-bold bg-gray-300 mb-8">{headerText}</div>;
  };

  const getResidenceLabel = () => {
    if (event.name.toLowerCase().includes("zebbug")) {
      return "Zebbug Resident";
    }

    if (event.name.toLowerCase().includes("zurrieq")) {
      return "Zurrieq Resident";
    }
  };

  const renderBundleInfo = () => {
    return (
      <div className="font-bold mb-4 md:mb-8">
        Bundle Package will entitle you to the 5K distance of the bundled competitions at a discounted price of{" "}
        {event.bundlePrice?.fee}
      </div>
    );
  };

  const renderSubEventsFormItem = () => {
    return (
      <Form.Item
        name="subEvent"
        label="Event"
        style={{ textAlign: "left" }}
        rules={[
          {
            required: true,
            message: "Please select an event!",
          },
        ]}
      >
        <Radio.Group className="w-full">
          {event.subEvents.map((subEvent: any) => {
            if (event.lateEntries && subEvent.restrictLateEnries) {
              return null;
            }

            return (
              <div key={subEvent.ref} className="mb-4 w-full inline-flex">
                <div>
                  <div>
                    <Radio className="font-bold" value={subEvent.ref}>
                      {subEvent.ref}
                    </Radio>
                  </div>
                  <div className="text-sm ml-6 text-gray-600">{subEvent.note}</div>
                </div>
                <div className="flex-1 text-sm font-bold text-right mr-2">{subEvent.fee}</div>
              </div>
            );
          })}
        </Radio.Group>
      </Form.Item>
    );
  };

  const renderRegisterForm = () => {
    //const [form] = Form.useForm()

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const markup = (
      <div className="m-5 md:max-w-lg md:mx-auto">
        <Form {...formItemLayout} name="register" onFinish={handleRegisterEvent} scrollToFirstError>
          {event.isBundle && renderBundleInfo()}
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                type: "email",
                transform: (value) => value.trim(),
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your Email!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[
              {
                required: true,
                message: "Please input your First Name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            style={{ textAlign: "left" }}
            rules={[
              {
                required: true,
                message: "Please input your Last Name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="gender"
            label="Gender"
            style={{ textAlign: "left" }}
            rules={[
              {
                required: true,
                message: "Please input Gender!",
              },
            ]}
          >
            <Radio.Group style={{ marginLeft: "5px" }}>
              <Radio value="M">Male</Radio>
              <Radio value="F">Female</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="dob"
            label="Date of Birth"
            style={{ textAlign: "left" }}
            rules={[
              {
                type: "object" as const,
                required: true,
                message: "Please input your Date of Birth!",
              },
            ]}
          >
            <DatePicker format={"DD/MM/YYYY"} showToday={false} placeholder={"DD/MM/YYYY"} />
            {/* <span className='ml-3 text-gray-400'>Format (YYYY-MM-DD)</span> */}
          </Form.Item>
          <Form.Item
            name="contactNo"
            label="Contact No."
            style={{ textAlign: "left" }}
            rules={[
              {
                required: true,
                message: "Please input your contact number!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="club" label="Club (if any)" style={{ textAlign: "left" }} initialValue={"NA"}>
            <Select placeholder="Select a club" onChange={(val) => (props.store.selectedClub = val)} showSearch>
              <Select.Option value="NA">No Club</Select.Option>
              <Select.Option value="Zurrieq Wolves AC">Zurrieq Wolves AC</Select.Option>
              <Select.Option value="Athleta Pembroke">Athleta Pembroke</Select.Option>
              <Select.Option value="Evolve Endurance Club">Evolve Endurance Club</Select.Option>
              <Select.Option value="Mellieha AC">Mellieha AC</Select.Option>
              <Select.Option value="La Salle">La Salle</Select.Option>
              <Select.Option value="Rush AC">Rush AC</Select.Option>
              <Select.Option value="Savio AC">Savio AC</Select.Option>
              <Select.Option value="Birkirkara St.Joseph">Birkirkara St.Joseph</Select.Option>
              <Select.Option value="Ladies Running Club">Ladies Running Club</Select.Option>
              <Select.Option value="Athletix Gozo">Athletix Gozo</Select.Option>
              <Select.Option value="St.Patricks AC">St.Patricks AC</Select.Option>
              <Select.Option value="Other">Other</Select.Option>
            </Select>
          </Form.Item>

          {props.store.selectedClub === "Other" && (
            <Form.Item name="otherClub" label="Club Name" style={{ textAlign: "left" }}>
              <Input />
            </Form.Item>
          )}

          {!event.isBundle &&
            (event.name.toLowerCase().includes("zebbug") || event.name.toLowerCase().includes("zurrieq")) && (
              <Form.Item name="resident" label={getResidenceLabel()} style={{ textAlign: "left" }}>
                <Radio.Group style={{ marginLeft: "5px" }}>
                  <Radio value="true">Yes</Radio>
                  <Radio value="false">No</Radio>
                </Radio.Group>
              </Form.Item>
            )}

          {!event.isBundle && event.subEvents && event.subEvents.length > 0 && renderSubEventsFormItem()}

          <div className="text-left d mb-2">Disclaimer</div>
          <div className="bg-gray-50 h-36 overflow-auto text-justify mb-4 p-2 border border-solid border-gray-300 rounded-md">
            By submitting form, I declare that I will abide by the eligibility and competition rules of Athletics Malta
            and Zurrieq Wolves Athletic Events. I accept that the organisers, helpers, sponsors or any other member of
            the organization, will not be held liable for any loss, damage or injury I may sustain as a consequence of
            my participation in the event. I declare that I have trained sufficiently for the event and that a medical
            doctor has monitored my physical condition over the past few months and that I am fit to run this race.
            Additionally, I hereby declare that the above particulars are complete, correct in every detail.
          </div>
          {event.notifyLateEntries && (
            <div className="font-semibold text-red-700 text-right mb-4">Entry not eligible for medal and goody bag</div>
          )}
          <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              htmlType="submit"
              className="bg-gray-500"
              onKeyDown={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
            >
              Register
            </Button>
          </Form.Item>
        </Form>
      </div>
    );

    return markup;
  };

  if (!event) {
    return <div className="mt-10 font-bold">Event not found</div>;
  }

  if (!event.active) {
    return <div className="mt-10 font-bold">Sorry! This event is no longer active.</div>;
  }

  //check if event date expired
  if (dayjs(event.eventDate).isBefore(dayjs())) {
    return <div className="mt-10 font-bold">Event Expired!</div>;
  }

  //check if limit has been reached
  if (event.entriesLeft !== null && event.entriesLeft <= 0 && !event.lateEntries) {
    return <div className="mt-10 font-bold">Event Sold Out!</div>;
  }

  return (
    <div>
      {contextHolder}
      {renderHeader()}
      {renderRegisterForm()}
    </div>
  );
});

export default Registration;
