import React, { Component } from "react";

//import { AppState } from '../../stores/AppState';

import { inject, observer, Provider } from "mobx-react";
import { AppStore } from "~/stores/AppStore";
import { RouteComponentProps } from "@gatsbyjs/reach-router";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { AdminController } from "../AdminController";
import { Select } from "antd";
import { makeObservable, observable } from "mobx";
import BillingTable from "./BillingTable";
import AdminToolbar from "./BillingToolbar";
import Loading from "../../../components/Loading/Loading";

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault("Europe/Rome");

interface Props {
  store?: any;
}

@inject("store")
@observer
export default class Billing extends Component<Props & RouteComponentProps> {
  store: AppStore;
  adminController: AdminController;
  // subEvents: any[];
  // selectedSubEvent: any;

  constructor(props: any) {
    super(props);
    // makeObservable(this, {
    //   selectedSubEvent: observable,
    //   subEvents: observable,
    // });

    this.store = props.store;

    if (!this.store.accessToken) {
      document.location.href = "/admin";
    }

    this.adminController = new AdminController(this.store);

    this.loadDependencies();
  }

  componentDidMount() {}

  loadDependencies = async () => {
    await this.adminController.loadEvents();
  };

  render() {
    console.log("RENDER BILLING");

    return (
      <Provider controller={this.adminController}>
        <div>
          {/* <div className="p-2 bg-orange-200 flex items-center justify-center text-lg font-bold">Admin Management</div> */}
          <AdminToolbar />
          <div className="table-overflow">
            <BillingTable />
          </div>
          <Loading active={this.adminController.isLoading} />
        </div>
      </Provider>
    );
  }
}
