import React, { Component } from "react";

import { inject, observer } from "mobx-react";
import { AppStore } from "~/stores/AppStore";
import { RouteComponentProps } from "@gatsbyjs/reach-router";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { AdminController } from "./AdminController";
import type { MenuProps } from "antd";
import { Button, Dropdown, Input, Select } from "antd";
import { PlusOutlined, ReloadOutlined, DownloadOutlined } from "@ant-design/icons";

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault("Europe/Rome");

interface Props {
  store?: any;
}

@inject("store", "controller")
@observer
export default class AdminToolbar extends Component<Props & RouteComponentProps> {
  store: AppStore;
  adminController: AdminController;

  constructor(props: any) {
    super(props);
    // makeObservable(this, {
    //   selectedEvent: observable,
    // });

    this.store = props.store;
    this.adminController = props.controller;
  }

  componentDidMount() {}

  exportSelection = (bibSupplier) => {
    const csv = this.adminController.exportSelectionToCSV(this.adminController.filteredRegistrations, bibSupplier);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "registrations.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  renderToolbar = () => {
    const events = this.adminController.events
      .filter((event) => !event.isBundle)
      .map((event) => {
        return {
          label: event.name + " - " + dayjs(event.eventDate).format("YYYY"),
          value: event._id,
        };
      });

    const subEvents = this.adminController.subEvents.map((subEvent) => {
      return {
        label: subEvent.ref,
        value: subEvent.ref,
      };
    });

    const items: MenuProps["items"] = [
      {
        label: <div onClick={() => this.exportSelection(true)}>Export CSV (Bib Supplier)</div>,
        key: "0",
      },
      {
        label: <div onClick={() => this.exportSelection(false)}>Export CSV (Full)</div>,
        key: "1",
      },
    ];

    return (
      <div className="p-3 bg-gray-300 md:flex items-center justify-center text-lg font-bold">
        <div className=" md:flex-1 text-left">
          <Select
            className="md:w-96 w-full mb-2 md:mb-0"
            placeholder="Select Event"
            onChange={(event) => this.adminController.loadRegistrations(event)}
            options={events}
          />
          {subEvents.length > 0 && (
            <Select
              className="md:ml-2 mb-2 md:mb-0"
              placeholder="Select Sub Event"
              style={{ width: 160 }}
              value={this.adminController.selectedSubEvent}
              onChange={(subEvent) => this.adminController.filterRegistrationsBySubEvent(subEvent)}
              options={subEvents}
            />
          )}
          <div className="hidden md:inline-flex md:ml-2">
            {this.adminController.selectedEvent && (
              <Dropdown menu={{ items }} trigger={["click"]}>
                <Button icon={<DownloadOutlined />} className="mr-2" />
              </Dropdown>
            )}
          </div>
          {this.adminController.eventRegistrations.length > 0 && (
            <div className="inline-flex ml-4 font-medium text-sm ">
              {`Entries: ${(this, this.adminController.filteredLength)} / ${
                this.adminController.eventRegistrations.length
              }`}
            </div>
          )}
        </div>
        <div className="hidden md:block">
          {this.adminController.selectedEvent && (
            <Button
              icon={<ReloadOutlined />}
              className="mr-2"
              onClick={() => {
                this.adminController.loadRegistrations(this.adminController.selectedEvent._id);
              }}
            />
          )}
        </div>

        <div className="hidden md:block">
          {this.adminController.selectedEvent && (
            <Button
              icon={<PlusOutlined />}
              className="mr-2"
              onClick={() => {
                this.adminController.registrationType = "create";
                this.adminController.isRegModalOpen = true;
                this.adminController.selectedRegistration = {};
              }}
            />
          )}
        </div>
        <div>
          <Input.Search
            allowClear
            onChange={(input) => this.adminController.filterRegistrationsBySearch(input.target.value)}
          />
        </div>
      </div>
    );
  };

  render() {
    console.log("RENDER TOOLBAR");

    return <div>{this.renderToolbar()}</div>;
  }
}
