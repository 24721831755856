import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

interface Props {}

const Logo: FC<Props> = (props) => {
  const navigate = useNavigate();

  let logo = "/images/running_malta_logo.png";

  if (process.env.REACT_APP_CLIENT_REF === "ZWA") {
    logo = "/images/ZWEventsLogo.png";
  }

  return (
    <div className="bg-black flex-1">
      <img className="ml-5 cursor-pointer h-24" src={logo} alt="logo" onClick={() => navigate("/")} />
    </div>
  );
};

export default Logo;
