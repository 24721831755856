import React, { useEffect } from "react";
import "./App.css";

import { Provider } from "mobx-react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { AppStore } from "./stores/AppStore";
import Landing from "./views/landing/Landing";
import Header from "./components/Header/Header";
import Registration from "./views/registration/Registration";
import ThankYou from "./views/registration/ThankYou";
import AdminLogin from "./views/admin/AdminLogin";
import AdminManage from "./views/admin/AdminManage";
import Billing from "./views/admin/billing/Billing";

const appStore = AppStore.getInstance();

function App() {
  useEffect(() => {
    if (appStore.clientRef === "ZWA") {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link["rel"] = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }

      document.title = "ZW Athletic Events";
      link["href"] = "/favicon_zw.ico";
    }
  }, []);

  return (
    <Provider store={appStore}>
      <BrowserRouter>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/register/:eventId" element={<Registration store={appStore} />} />
            <Route path="/thankyou" element={<ThankYou />} />
            <Route path="/admin" element={<AdminLogin store={appStore} />} />
            <Route path="/admin/manage" element={<AdminManage />} />
            <Route path="/admin/billing" element={<Billing />} />
            {/* <div className="text-green-500 font-bold">TEST</div> */}
            {/* <Router> */}
            {/* <ClubRecords path="/test" /> */}
          </Routes>
          {/* </Router> */}
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
