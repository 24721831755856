import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input, Form, Alert } from "antd";
import { observer, useLocalObservable } from "mobx-react";

interface Props {
  store: any;
}

const AdminLogin: FC<Props> = observer((props) => {
  const navigate = useNavigate();

  const error = useLocalObservable(() => ({ message: "" }));

  console.log("RENDER LOGIN");
  //clear token
  props.store.accessToken = "";
  localStorage.removeItem("zw_events_session");

  const handleLogin = async (form) => {
    const login = await props.store.postData("auth/login", form);

    if (login.status === 200) {
      props.store.accessToken = login.data.accessToken;
      props.store.clientRef = form.clientRef;

      const session = {
        accessToken: login.data.accessToken,
        clientRef: form.clientRef,
      };
      localStorage.setItem("zw_events_session", JSON.stringify(session));
      navigate("/admin/manage");
    } else {
      error.message = "Invalid username or password!";
    }
  };

  let logo = "images/running_malta_logo_original.png";
  if (process.env.REACT_APP_CLIENT_REF === "ZWA") {
    logo = "images/ZWEventsLogo.png";
  }

  return (
    <div className="flex h-full w-full fixed bg-gray-200">
      <div className="m-auto p-5 rounded-md bg-white mt-20" style={{ width: "320px" }}>
        {/* <h2 className='mb-5' style={{ fontFamily: 'Segoe UI', fontSize: '18px' }}>Zurrieq Wolves Members Portal</h2> */}
        {/* <img src="images/club_logo.png" style={{ width: "250px" }} className="mt-3 mx-auto mb-8" alt="logo" /> */}
        <div>
          <img src={logo} className="mt-3 mx-auto mb-4" alt="logo" />
          <div className="mb-6 text-lg font-bold">Management Portal</div>
        </div>
        <Form onFinish={handleLogin}>
          <Form.Item name="clientRef" className="mb-4">
            <Input name="clientRef" placeholder="Client Ref" />
          </Form.Item>
          <Form.Item name="email" className="mb-4">
            <Input name="email" placeholder="Email" />
          </Form.Item>
          <Form.Item name="password" className="mb-4">
            <Input.Password type="text" name="password" placeholder="Password" />
          </Form.Item>
          {error.message && <Alert message={error.message} type="error" showIcon style={{ marginTop: "10px" }} />}
          <div className="text-right mt-3">
            <Button type="primary" htmlType="submit" className="bg-blue-600">
              Login
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
});

export default AdminLogin;
