import { makeObservable, observable } from "mobx";
import { AppStore } from "~/stores/AppStore";

export class LandingController {
  store: AppStore;
  showLateEntryModal: boolean = false;
  selectedEvent: any = null;

  constructor(store: any) {
    this.store = store;

    makeObservable(this, {
      showLateEntryModal: observable,
    });
  }
}
