import React, { FC } from "react";

import { inject, observer, Provider } from "mobx-react";
import { AppStore } from "~/stores/AppStore";
import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { makeObservable, observable } from "mobx";
import { AppstoreOutlined, MailOutlined, LogoutOutlined } from "@ant-design/icons";

import { useNavigate } from "react-router";

interface Props {
  store?: any;
}

const Footer: FC<Props> = inject("store")(
  observer((props) => {
    //const navigate = useNavigate();

    console.log("RENDER FOOTER");

    return (
      <div
        className="fixed text-sm bottom-0 p-3 bg-blue-50  border border-slate-300 border-solid w-full"
        style={{ maxWidth: "-webkit-fill-available" }}
      >
        Use our booking engine to host your event. Contact us on{" "}
        <a href="mailto:info@runningmalta.com">info@runningmalta.com</a> for more information.
      </div>
    );
  })
);

export default Footer;
