import { computed, makeObservable, observable, runInAction } from "mobx";
import dayjs from "dayjs";
import { AppStore } from "~/stores/AppStore";

export class AdminController {
  store: AppStore;
  events: any = [];
  subEvents: any = [];
  selectedEvent: any = null;
  selectedSubEvent: any = null;
  eventRegistrations: any = [];
  filteredRegistrations: any = [];
  filteredLength: number = 0;
  isRegModalOpen: boolean = false;
  selectedRegistration: any = null;
  registrationType: string = "";
  isLoading: boolean = false;

  constructor(store: any) {
    this.store = store;

    makeObservable(this, {
      events: observable,
      selectedSubEvent: observable,
      eventRegistrations: observable,
      filteredRegistrations: observable,
      subEvents: observable,
      filteredLength: observable,
      isRegModalOpen: observable,
      isLoading: observable,
      //filteredCount: computed,
    });
  }

  loadBillingReport = async (eventId: any) => {
    try {
      this.isLoading = true;
      this.eventRegistrations = await this.store.getData(`admin/billing/${eventId}`);
    } catch (e) {
      console.error(e);
      document.location.href = "/admin";
    }
    runInAction(() => {
      this.filteredRegistrations = this.eventRegistrations;
      this.selectedEvent = this.events.find((e: any) => e._id === eventId);
      this.subEvents = [{ ref: "All" }, ...this.selectedEvent.subEvents];
      this.selectedSubEvent = "All";
      this.filteredLength = this.filteredRegistrations.length;
      this.isLoading = false;
    });
  };

  loadRegistrations = async (eventId: any) => {
    try {
      this.isLoading = true;
      this.eventRegistrations = await this.store.getData(`events/${eventId}/registrations`);
    } catch (e) {
      console.error(e);
      document.location.href = "/admin";
    }
    runInAction(() => {
      this.filteredRegistrations = this.eventRegistrations;
      this.selectedEvent = this.events.find((e: any) => e._id === eventId);
      this.subEvents = [{ ref: "All" }, ...this.selectedEvent.subEvents];
      this.selectedSubEvent = "All";
      this.filteredLength = this.filteredRegistrations.length;
      this.isLoading = false;
    });
  };

  filterRegistrationsBySubEvent = (subEventRef: any) => {
    runInAction(() => {
      this.isLoading = true;
      this.selectedSubEvent = subEventRef;
      if (subEventRef === "All") {
        this.filteredRegistrations = this.eventRegistrations;
      } else {
        this.filteredRegistrations = this.eventRegistrations.filter((r: any) => r.subEvent === subEventRef);
      }
      this.isLoading = false;
    });
  };

  filterRegistrationsBySearch = (searchText: any) => {
    runInAction(() => {
      this.filteredRegistrations = this.eventRegistrations.filter((r: any) => {
        return (
          r.bib === searchText ||
          r.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
          r.lastName?.toLowerCase().includes(searchText.toLowerCase()) ||
          r.email?.toLowerCase().includes(searchText.toLowerCase())
        );
      });
    });
  };

  // filteredCount = () => {
  //   const assigned = this.eventRegistrations.filter((r: any) => r.bib).length;
  //   const paid = this.eventRegistrations.filter((r: any) => r.paid).length;

  //   return { assigned: assigned, paid: paid };
  // };

  loadEvents = async () => {
    this.isLoading = true;
    this.events = await this.store.getData("events/admin?clientRef=" + this.store.clientRef);
    this.isLoading = false;
  };

  createRegistration = async (registration: any) => {
    try {
      runInAction(async () => {
        registration.type = "new";
        registration.paid = registration.paid === true;
        registration.dob = dayjs(registration.dob).format("YYYY-MM-DD");

        const result = await this.store.postData(`events/${this.selectedEvent._id}/register`, registration);

        console.log(result);

        this.eventRegistrations.push(result.data);

        //   const index = this.eventRegistrations.findIndex((evtReg) => evtReg._id === registration._id);
        //   const updatedRegistration = await this.store.putData(
        //     `events/${registration.event._id}/registrations/${registration._id}`,
        //     registration
        //   );
        //   this.eventRegistrations[index] = updatedRegistration;
        //   const filteredIndex = this.filteredRegistrations.findIndex((evtReg) => evtReg._id === registration._id);
        //   this.filteredRegistrations[filteredIndex] = updatedRegistration;
        this.isRegModalOpen = false;
      });

      // this.loadRegistrations(this.selectedEvent._id);
    } catch (e) {
      console.error(e);
    }
  };

  updateRegistration = async (registration: any) => {
    try {
      console.log(registration);
      runInAction(async () => {
        const index = this.eventRegistrations.findIndex((evtReg) => evtReg._id === registration._id);
        const updatedRegistration = await this.store.putData(
          `events/${registration.event._id}/registrations/${registration._id}`,
          registration
        );
        this.eventRegistrations[index] = updatedRegistration;
        const filteredIndex = this.filteredRegistrations.findIndex((evtReg) => evtReg._id === registration._id);
        this.filteredRegistrations[filteredIndex] = updatedRegistration;
        this.isRegModalOpen = false;
      });

      // this.loadRegistrations(this.selectedEvent._id);
    } catch (e) {
      console.error(e);
    }
  };

  deleteRegistration = async () => {
    const registration = this.selectedRegistration;

    try {
      runInAction(async () => {
        await this.store.deleteData(`events/${registration.event._id}/registrations/${registration._id}`);
        this.eventRegistrations = this.eventRegistrations.filter((evtReg) => evtReg._id !== registration._id);
        this.filteredRegistrations = this.filteredRegistrations.filter((evtReg) => evtReg._id !== registration._id);
        this.isRegModalOpen = false;
      });
      return true;
    } catch (err) {
      console.error(err);
    }
  };

  exportSelectionToCSV = (exportData, bibSupplier = false) => {
    const csvRows = [];
    let headers = [
      "bib",
      "firstName",
      "lastName",
      "gender",
      "dob",
      "age",
      "category",
      "resident",
      "club",
      "paid",
      "subEvent",
      "createdAt",
    ];

    if (!bibSupplier) {
      headers.push(...["_id", "email", "contactNo", "isBundle"]);
    }

    // let headers = bibSupplier ? presetHeaders : Object.keys(exportData[0]);
    csvRows.push(headers.join(","));

    for (const item of exportData) {
      const values = headers.map((header) => {
        const escaped = ("" + item[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    }

    return csvRows.join("\n");
  };
}
