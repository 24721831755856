import React, { FC } from "react";

//import { AppState } from '../../stores/AppState';

import { inject, observer, Provider } from "mobx-react";
import { AppStore } from "~/stores/AppStore";
import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { makeObservable, observable } from "mobx";
import { AppstoreOutlined, MailOutlined, LogoutOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Button, Menu, MenuProps } from "antd";
import Logo from "./components/Logo";
import { useNavigate } from "react-router";

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault("Europe/Rome");

interface Props {
  store?: any;
}

const Header: FC<Props> = inject("store")(
  observer((props) => {
    const navigate = useNavigate();

    console.log("RENDER HEADER");

    return (
      <div className="flex text-left">
        <Logo />
        {props.store.accessToken && (
          <div className="bg-black pr-4" style={{ lineHeight: "95px" }}>
            <div className="inline text-white mr-4 cursor-pointer" onClick={() => navigate("/admin/manage")}>
              {props.store.clientRef}
            </div>
            <Button size="large" icon={<LogoutOutlined />} onClick={props.store.signOut} />
          </div>
        )}
      </div>
    );
  })
);

export default Header;
