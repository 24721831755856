import React, { FC } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Result } from "antd";

interface Props {}

const ThankYou: FC<Props> = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const cancelled = searchParams.get("cancelled") === "true";

  if (cancelled) {
    return (
      <Result
        className="m-8 border border-gray-400 rounded-md bg-white"
        status="error"
        title="Payment Failed or Cancelled!"
        subTitle="Please retry registration. If the problem persists, please contact us on our FB Events Page or email (zurrieqwolvesathletics@gmail.com)."
        extra={[
          <Button type="primary" key="console" className="bg-gray-500" onClick={() => navigate("/")}>
            Go to Events
          </Button>,
        ]}
      />
    );
  } else {
    return (
      <Result
        className="m-8 border border-gray-400 rounded-md bg-white"
        status="success"
        title="Thank you for registering!"
        subTitle="Your registration has been received and payment is being processed. You should be receiving an email confirmation. Please follow our FB Events Page for any updates. We will be sending a final email with all the details a few days before the event. "
        extra={[
          <Button type="primary" key="console" className="bg-gray-500" onClick={() => navigate("/")}>
            Back to Events
          </Button>,
        ]}
      />
    );
  }
};

export default ThankYou;
