//import { redirectTo } from "@gatsbyjs/reach-router";
import axios from "axios";
import { makeObservable, observable } from "mobx";

export class AppStore {
  private apiAddress: string;

  public accessToken: any = null;
  public clientRef: string = null;
  public events: any = [];
  public selectedEvent = null;
  public selectedClub: any = null;
  static instance: any;

  static getInstance() {
    this.instance = this.instance || new AppStore();
    return this.instance;
  }

  constructor() {
    makeObservable(this, {
      events: observable,
      selectedClub: observable,
      selectedEvent: observable,
      clientRef: observable,
      accessToken: observable,
    });

    this.apiAddress = process.env.REACT_APP_API_ENDPOINT || "";
    this.clientRef = process.env.REACT_APP_CLIENT_REF || "";

    const sessionLocalStorage = localStorage.getItem("zw_events_session");
    if (sessionLocalStorage) {
      const session = JSON.parse(sessionLocalStorage);
      this.accessToken = session.accessToken;
      this.clientRef = session.clientRef;
    }
  }

  loadEvents = async () => {
    this.events = await this.getData("events");
  };

  loadEvent = async (eventId: string) => {
    this.selectedEvent = this.events.find((event: any) => event._id === eventId);

    if (!this.selectedEvent) {
      this.selectedEvent = await this.getData(`events/${eventId}`);
    }
  };

  signOut = () => {
    //runInAction(() => {
    //this.authenticated = false;
    localStorage.removeItem("zw_events_accessToken");
    document.location.href = "/admin";
    //redirectTo("/");
    //});
  };

  isApiWhitelisted = (api: string) => {
    const whitelist = [
      "login",
      "register",
      "signout",
      "verify",
      "forgot-password",
      "verify-password-reset",
      "reset-password",
    ];

    if (api.startsWith("lookupUser")) {
      return true;
    }

    if (whitelist.includes(api)) {
      return true;
    }

    return false;
  };

  async getData(queryString: string) {
    const config: any = this.accessToken && {
      headers: {
        Authorization: "Bearer " + this.accessToken,
      },
    };

    const { data } = await axios.get(this.apiAddress + queryString, config).catch((error: any) => {
      console.error(error);
      throw error;
    });

    return data;
  }

  async postData(queryString: string, data: any) {
    // const config: any = !this.isApiWhitelisted(queryString) && {
    //   headers: {
    //     Authorization: "Bearer " + this.userSession.accessToken,
    //   },
    // };

    const config: any = {};

    const result = await axios.post(this.apiAddress + queryString, data, config).catch((error) => {
      console.error(error);
      return error.response;
    });

    return result;
  }

  async putData(queryString: string, payload: any) {
    const config: any = this.accessToken && {
      headers: {
        Authorization: "Bearer " + this.accessToken,
      },
    };

    try {
      const { data } = await axios.put(this.apiAddress + queryString, payload, config);
      return data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async deleteData(queryString: string) {
    const config: any = this.accessToken && {
      headers: {
        Authorization: "Bearer " + this.accessToken,
      },
    };

    try {
      const { data } = await axios.delete(this.apiAddress + queryString, config);
      return data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}
