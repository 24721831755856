import React, { Component } from "react";

//import { AppState } from '../../stores/AppState';

import { inject, observer, Provider } from "mobx-react";
import { AppStore } from "~/stores/AppStore";
import { RouteComponentProps } from "@gatsbyjs/reach-router";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { AdminController } from "./AdminController";
import { makeObservable, observable, runInAction } from "mobx";
import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { UserOutlined, CheckOutlined, CloseOutlined, EditOutlined, EuroOutlined } from "@ant-design/icons";
import { RegistrationModal } from "./components/RegistrationModal";

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault("Europe/Rome");

interface Props {
  store?: any;
}

@inject("store", "controller")
@observer
export default class AdminRegistrations extends Component<Props & RouteComponentProps> {
  store: AppStore;
  adminController: AdminController;

  constructor(props: any) {
    super(props);
    // makeObservable(this, {
    //   selectedEvent: observable,
    // });

    this.store = props.store;
    this.adminController = props.controller;
  }

  componentDidMount() {}

  handleItemEdit = (item) => {
    this.adminController.registrationType = "edit";
    this.adminController.selectedRegistration = item;
    this.adminController.isRegModalOpen = true;
  };

  renderTable = () => {
    const columns: ColumnsType = [
      {
        title: "Entries",
        dataIndex: "",
        key: "mobile",
        render: this.renderMobileView,
        responsive: ["xs"],
      },
      {
        title: "",
        dataIndex: "",
        key: "actions",
        width: 10,
        align: "center",
        render: (item) => {
          return <Button size="small" icon={<EditOutlined />} onClick={() => this.handleItemEdit(item)} />;
        },
        responsive: ["sm"],
      },
      {
        title: "Bib",
        dataIndex: "bib",
        key: "bib",
        width: 80,
        align: "center",
        responsive: ["sm"],
        render: (bib) => {
          return bib && <div className="font-bold border-gray-400 border bg-white py-2 rounded-sm">{bib}</div>;
        },
      },
      {
        title: "Event",
        dataIndex: "subEvent",
        key: "subEvent",
        width: 170,
        align: "left",
        responsive: ["sm"],
      },
      {
        title: "First Name",
        dataIndex: "firstName",
        key: "firstName",
        width: 160,
        responsive: ["sm"],
      },
      {
        title: "Last Name",
        dataIndex: "lastName",
        key: "lastName",
        width: 200,
        responsive: ["sm"],
      },
      {
        title: "Gender",
        dataIndex: "gender",
        key: "gender",
        width: 60,
        align: "center",
        render: (gender) =>
          gender === "M" ? <UserOutlined className="text-blue-400" /> : <UserOutlined className="text-pink-400" />,
        responsive: ["sm"],
      },
      {
        title: "DOB",
        dataIndex: "dob",
        key: "dob",
        width: 80,
        align: "center",
        render: (date) => dayjs(date).format("DD/MM/YYYY"),
        responsive: ["sm"],
      },
      {
        title: "Age",
        dataIndex: "age",
        key: "age",
        width: 60,
        align: "center",
        responsive: ["sm"],
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        width: 60,
        align: "center",
        responsive: ["sm"],
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        responsive: ["sm"],
      },
      {
        title: "Bundle",
        dataIndex: "isBundle",
        key: "isBundle",
        width: 80,
        align: "center",
        render: (isBundle) => {
          return isBundle ? <CheckOutlined className="text-green-600" /> : <CloseOutlined className="text-red-600" />;
        },
        responsive: ["sm"],
      },
      {
        title: "Late",
        dataIndex: "lateEntry",
        key: "lateEntry",
        width: 80,
        align: "center",
        render: (lateEntry) => {
          return lateEntry ? <CheckOutlined className="text-green-600" /> : <CloseOutlined className="text-red-600" />;
        },
        responsive: ["sm"],
      },
      {
        title: "Enrolled",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 80,
        align: "center",
        render: (date) => dayjs(date).format("DD/MM/YYYY"),
        responsive: ["sm"],
      },
      {
        title: "Paid",
        dataIndex: "paid",
        key: "paid",
        width: 80,
        align: "center",
        filters: [
          {
            text: "YES",
            value: true,
          },
          {
            text: "NO",
            value: false,
          },
        ],
        filtered: false,
        onFilter: (value: string, record) => {
          return record["paid"] === value;
        },
        filterMultiple: false,
        render: (status) => {
          let textColor = !status ? "text-red-600" : "text-green-600";
          textColor += " font-bold";
          const text = !status ? "NO" : "YES";
          return <div className={textColor}>{text}</div>;
        },
        responsive: ["sm"],
      },
    ];

    return (
      <Table
        rowKey="_id"
        columns={columns}
        dataSource={[...this.adminController.filteredRegistrations]}
        pagination={false}
        className="table-striped-rows"
        virtual={true}
        // onChange={(pagination, filters, sorter, extra) => {
        //   this.adminController.filteredLength = extra.currentDataSource.length;
        // }}
        summary={(pageData) => {
          runInAction(() => {
            this.adminController.filteredLength = pageData.length;
          });
          return null;
        }}
      />
    );
  };

  renderMobileView = (field, item) => {
    const genderIcon =
      item.gender === "M" ? <UserOutlined className="text-blue-400" /> : <UserOutlined className="text-pink-400" />;

    let paidTextColor = !item.paid ? "text-red-600" : "text-green-600";
    paidTextColor += " w-8 text-center";

    return (
      <div>
        <div className="mb-1 flex items-center">
          {item.bib && (
            <span className="w-10 font-bold border-gray-400 border bg-white py-2 rounded-sm text-center mr-2">
              {item.bib}
            </span>
          )}
          <span className="flex-1">{item.firstName + " " + item.lastName}</span>
          <span className="w-20">{dayjs(item.dob).format("DD/MM/YYYY")}</span>
          <span className={paidTextColor}>
            <EuroOutlined />
          </span>
          <span className="text-center w-8">{genderIcon}</span>
          <span className="text-right w-8">
            <Button size="small" icon={<EditOutlined />} onClick={() => this.handleItemEdit(item)} />
          </span>
        </div>
        {/* <div>{}</div> */}
        {/* <div className="mb-2">{dayjs(item.date).format("DD/MM/YYYY")}</div>
        <div className="mb-2">{item.meeting}</div>
        <div className="mb-1">{item.mark}</div> */}
      </div>
    );
  };

  render() {
    console.log("RENDER TABLE");

    return <div>{this.renderTable()}</div>;
  }
}
