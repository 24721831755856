import React, { FC } from "react";
import { Spin } from "antd";

interface Props {
  active: boolean;
}

const Loading: FC<Props> = (props) => {
  return (
    props.active && (
      <div className="h-full w-full absolute bg-opacity-50 bg-gray-500 top-0 z-10">
        <div className="-translate-y-2/4 top-2/4 relative -translate-x-2/4 left-2/4 p-5 bg-white w-14">
          <Spin size="large" />
          <div className="mt-5">Loading</div>
        </div>
      </div>
    )
  );
};

export default Loading;
